<template>
	<div class="wrap">
		<myHead></myHead>

		<div class="wrap_body" v-loading="is_loading">
			<div class="full-center">
				<div class="center">
					<div class="search_wrap">
						<div class="search_box">
							<el-dropdown class="type" trigger="click" @command="typeCommand">
								<span class="el-dropdown-link">
									{{searchParams.name_type==1?'档口':searchParams.name_type==2?'货号':searchParams.name_type==3?'图搜':'宝贝'}}<i
										class="el-icon-arrow-down el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="0">宝贝</el-dropdown-item>
									<!-- <el-dropdown-item command="1">档口</el-dropdown-item> -->
									<el-dropdown-item command="2">货号</el-dropdown-item>
									<el-dropdown-item command="3">图搜</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<input class="in"
								:placeholder="searchParams.name_type==1?'输入档口号或店招名称':searchParams.name_type==2?'输入货号搜索商品':searchParams.name_type==3?'图搜':'输入关键字搜索商品'"
								v-model="searchParams.name" @keyup.enter="refresh()" />
							<i class="iconfont icon-xiangji" @click.stop="gotoImageSearch()"></i>
						</div>
						<button class="search_btn" @click.stop="refresh()">搜索</button>
					</div>
					<div class="filter-title">
						<div class="search-input">
							<div class="label">搜索内容 ></div>
						<div class="val">“{{searchText}}“</div>
					</div>
					<div class="filter-box" :class="showFilter?'show':'hide'">
						<div class="filter-header">
							<div class="header-left">
								<span class="label">已选条件</span>
								<span class="val">{{filterText}}</span>
								<div class="reset" @click="reset()" v-if="filterText">
									<span>重置</span>
									<i class="el-icon-refresh-right"></i>
								</div>
							</div>
							<div class="header-right" @click="bindFilterShow()">
								<span>隐藏筛选</span>
								<i class="el-icon-arrow-up el-icon--up"></i>
							</div>
						</div>
						<div class="filter-list">
							<div class="label">地区</div>
							<div class="list">
								<div class="item" :class="!searchParams.site_id?'active':''" @click="bindSiteTap(0)">全部</div>
								<div class="item" :class="searchParams.site_id==item.site_id?'active':''" v-for="(item,index) in siteList" :key="index" @click="bindSiteTap(item.site_id)">{{item.site_name}}</div>
							</div>
						</div>
						<!-- <div class="filter-list">
							<div class="label">市场</div>
							<div class="list">
								<div class="item" :class="!searchParams.market_id?'active':''" @click.stop="bindMarketTap(0)">全部</div>
								<template v-for="(market,index) in marketList">
									<div class="item" :class="searchParams.market_id==market.market_id?'active':''"
										@click.stop="bindMarketTap(market.market_id)">{{market.market_name}}</div>
								</template>
							</div>
						</div> -->
						<div class="filter-list">
							<div class="label">类目</div>
							<div class="list">
								<div class="item" :class="!searchParams.cate_id[0]?'active':''" @click.stop="bindCateGroupTab(null)">全部</div>
								<div class="item" v-for="(cate,index) in cateGroupList" :key="index" :class="searchParams.cate_id[0]==cate.cate_ids?'active':''" @click="bindCateGroupTab(cate)">{{cate.name}}</div>
							</div>
						</div>
						<div class="filter-list" v-if="subCateList.length > 0">
							<div class="label">分类</div>
							<div class="list">
								<div class="item" :class="!searchParams.cate_id[1]?'active':''" @click.stop="bindCateTap(0)">全部</div>
								<div class="item" :class="searchParams.cate_id[1]==cate.id?'active':''" v-for="(cate,index) in subCateList" :key="index" @click.stop="bindCateTap(cate.id)">{{cate.name}}</div>
							</div>
						</div>
						<div class="filter-list">
							<div class="label">风格</div>
							<div class="list">
								<div class="item" :class="!searchParams.style_id?'active':''" @click.stop="bindStyleTap(0)">全部</div>
								<div class="item" v-for="(style,index) in styleList" :key="index" :class="searchParams.style_id==style.style_id?'active':''"  @click.stop="bindStyleTap(style.style_id)">{{style.style_name}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="center">
			<div class="home_main">

				<div class="product_section">
					<div class="screen_wrap">
						<!-- <p class="title">为你推荐</p> -->
            <p class="tab" :class="searchParams.sort_type==4?'active':''" @click="bindSortTypeTap(4)">综合排序</p>
						<p class="tab" :class="searchParams.sort_type==1?'active':''" @click="bindSortTypeTap(1)">新品</p>
						<p class="tab" :class="searchParams.sort_type==2?'active':''" @click="bindSortTypeTap(2)">销量</p>
						<p class="tab" :class="searchParams.sort_type==3?'active':''" @click="bindSortTypeTap(3)">人气</p>
						
						<!-- <p class="tab" :class="searchParams.free_express==1?'active':''" @click="searchTap('free_express',1)">包邮</p> -->
						<el-popover
						    placement="bottom"
								popper-class="ageing-box"
						    trigger="click"
								v-model="showAgeing">
								<div class="ageing-list">
									<div class="ageing-item" :class="searchParams.ageing_type==item.type?'active':''" v-for="(item,index) in ageingList" :key="index" @click="bindAgeingTap(item)">{{item.text}}</div>
								</div>
								<p class="tab" slot="reference">
									<template v-if="ageing_text==''">预售<i class="el-icon-arrow-down el-icon--right"></i></template>
									<template v-else>
										{{ageing_text}}<i @click.stop="bindCleanAgeing()" class="el-icon-close"></i>
									</template>
								</p>
						</el-popover>
						<el-popover
						    placement="bottom"
								popper-class="ageing-box"
						    trigger="click"
								v-model="showPublic">
								<div class="ageing-list">
									<div v-for="(item,index) in publicList" :key="index">
										<div class="ageing-item" :class="searchParams.public_type==item.type?'active':''" @click="bindPublicTap(item)" v-if="item.type!=999">{{item.text}}</div>
										<div class="ageing-item" v-if="item.type==999&&!showPublicRange" @click="bindPublicTap(item)">{{item.text}}</div>
									</div>
									
									<div class="ageing-item" v-if="showPublicRange">
									    <el-date-picker
												size="mini"
									      v-model="timeRange"
									      type="daterange"
									      range-separator="至"
									      start-placeholder="开始日期"
									      end-placeholder="结束日期"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd"
												@change="bindPublicRangeChange"
												>
									    </el-date-picker>
									  </div>
								</div>
								<p class="tab" slot="reference">
									<template v-if="public_text==''">上新<i class="el-icon-arrow-down el-icon--right"></i></template>
									<template v-else>
										{{public_text}}<i @click.stop="bindCleanPublic()" class="el-icon-close"></i>
									</template>
								</p>
						</el-popover>
					
						<el-dropdown class="tab" :class="searchParams.price_sort>0?'active':''" trigger="click"
							@command="priceCommand">
							<span class="el-dropdown-link">
								{{searchParams.price_sort==1?'价格从高到低':searchParams.price_sort==2?'价格从低到高':'价格'}}<i
									class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="0">默认</el-dropdown-item>
								<el-dropdown-item command="1">价格从高到低</el-dropdown-item>
								<el-dropdown-item command="2">价格从低到高</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<p class="in_box">￥<input class="in" v-model="searchParams.min_price" /></p>
						<p class="in_line">-</p>
						<p class="in_box">￥<input class="in" v-model="searchParams.max_price" /></p>
						<p class="in_btn" @click="refresh()">确定</p>
						<!-- <p class="in_btn" @click="reset()">清空</p> -->
						<p class="in_btn" @click="selectAll()">全选</p>
						<p class="in_btn" @click="allPuhuo()">一键铺货</p>
						<p class="totle-text">共找到{{page_data.total}}件商品</p>
					</div>
					
					<div class="ext-screen" style="justify-content: flex-start">
						<el-checkbox-group class="check-list" v-model="checkExtList" @change="bindCheckExtChange()">
							<!-- <el-checkbox :label="6">48小时专区</el-checkbox> -->
							<el-checkbox :label="7">优选专区</el-checkbox>
							<el-checkbox :label="1">可退货</el-checkbox>
							<el-checkbox :label="2">可换货</el-checkbox>
							<el-checkbox :label="3">合并同款</el-checkbox>
              <el-checkbox :label="5">关联收藏</el-checkbox>
              <el-checkbox :label="4">隐藏已铺货</el-checkbox>
						</el-checkbox-group>
            <div v-if="checkExtList==4" style="display: flex;align-items: center">
              <div style="margin: -1px 10px 0 10px;font-size: 12px;color: #666">店铺名</div>
              <el-select class="w290" @clear="refresh()" @change="refresh()" v-model="searchParams.shop_id" placeholder="请选择" clearable filterable size="mini">
                <el-option v-for="item in shopLists" :key="item.shop_id" :label="item.shop_name" :value="item.shop_id">
                </el-option>
              </el-select>
            </div>
						<!-- <div class="ageing-list" v-if="showAgeing">
							<div class="ageing-item" :class="searchParams.ageing_type==ageing.type?'active':''" v-for="(ageing,index) in ageingList" :key="index" @click="bindAgeingTap(ageing.type)">{{ageing.text}}</div>
						</div>
						<div class="ageing-list" v-if="showPublic">
							<div class="ageing-item" :class="searchParams.public_type==item.type?'active':''" v-for="(item,index) in publicList" :key="index" @click="bindPublicTap(item.type)">{{item.text}}</div>
						</div> -->
					</div>
					<productList style="padding: 0px 20px 20px;" :listData="list" :loading="product_loading" ref="products"></productList>

					<div class="turn_page">
						<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit"
							@pagination="getProduct" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20,50,100,200,500,1000]" />
					</div>

				</div>
			</div>
			<el-dialog class="login_mask" :visible.sync="is_login" width="346px" @close="closeMask">
				<div class="login_main">
					<div class="login_type" v-show="login_type!=3">
						<p :class="login_type==1?'tab active':'tab'" @click="codeLoginClick">扫码登录</p>
						<p :class="login_type==2?'tab active':'tab'" @click="accountLoginClick">账户登录</p>
					</div>
					<div class="code_login" v-show="login_type==1" v-loading="loadingCode">
						<img class="code" :src="imageCode" />
						<p class="txt">打开手机微信，扫描二维码</p>
					</div>
					<div class="account_login" v-show="login_type==2">
						<div v-if="!is_code_login">
							<el-input v-model="login_phone" placeholder="手机号登录" class="el_input">
								<p class="icons" slot="prefix"><i class="iconfont icon-yonghu"></i></p>
							</el-input>
							<el-input v-model="login_pwd" placeholder="密码" class="el_input" type="password">
								<p class="icons" slot="prefix"><i class="iconfont icon-clock"></i></p>
							</el-input>
						</div>
						<div v-else>
							<el-input v-model="login_phone" placeholder="输入手机号" class="el_input el_in2">
								<p slot="prefix" class="txts">手机号码</p>
							</el-input>
							<el-input v-model="login_code" placeholder="短信验证码" class="el_input el_in2">
								<p slot="prefix" class="txts">验证码</p>
								<p slot="suffix" class="getcode" @click="getPhoneCode('2')">
									{{is_get_phone_code?phone_second+'s重新获取':'获取验证码'}}</p>
							</el-input>
						</div>
						<p class="change_way">
							<el-checkbox v-model="remember_pwd">记住密码</el-checkbox>
							<span @click="is_code_login=!is_code_login">
								{{is_code_login?'账号密码登录':'验证码登录'}}
							</span>
						</p>
						<p class="login_btn" @click="loginSubmit">登录</p>
					</div>
					<div class="code_login" v-show="login_type==3" v-loading="loadingCode">
						<img class="code" :src="imageCode" />
						<p class="txt">扫码关注公众号并绑定手机号</p>
					</div>
					<p class="go_register">还没账户？<a @click="is_register=true;is_login=false;reg_step=1">去注册</a></p>
				</div>
			</el-dialog>
			<el-dialog class="register_mask" title="注册账号" :visible.sync="is_register" width="346px">
				<div class="register_main">
					<div class="step1" v-if="reg_step==1">
						<el-input v-model="yqm" disabled class="el_input el_in2" v-if="yqm">
							<p slot="prefix" class="txts">邀请码</p>
						</el-input>
						<el-input v-model="reg_phone" placeholder="输入手机号" class="el_input el_in2">
							<p slot="prefix" class="txts">手机号码</p>
						</el-input>
						<el-input v-model="reg_code" placeholder="短信验证码" class="el_input el_in2">
							<p slot="prefix" class="txts">验证码</p>
							<p slot="suffix" class="getcode" @click="getPhoneCode('1')">
								{{is_get_phone_code?phone_second+'s重新获取':'获取验证码'}}</p>
						</el-input>
						<el-input v-model="reg_pwd" type="password" placeholder="输入密码" class="el_input el_in2">
							<p slot="prefix" class="txts">设置密码</p>
						</el-input>
						<el-input v-model="reg_pwd_confirm" type="password" placeholder="再次输入密码" class="el_input el_in2">
							<p slot="prefix" class="txts">确认密码</p>
						</el-input>
						<p class="register_btn" @click="regSubmit">注册</p>
						<div class="check_line">
							<label class="la"><el-checkbox v-model="reg_checked"></el-checkbox>我已同意并阅读</label>
							<p class="link" @click="$router.push('./notice?type=4&id=3')">《星好货用户协议》</p>
						</div>
					</div>
					<div class="step2" v-if="reg_step==2" v-loading="loadingCode">
						<p class="title" style="padding-bottom: 50px;">扫码关注公众号</p>
						<img class="code" :src="imageCode" />
						<p class="code_txt">打开手机微信，扫描二维码</p>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
	<shopList ref="child"></shopList>
	<myFoot :showBeian="true"></myFoot>
	</div>
</template>

<script>
	import Vue from 'vue';
	import "swiper/swiper-bundle.min.css";
	import {
		mapActions,
		mapState,
		mapMutations
	} from "vuex";
	import Swiper, {
		Navigation,
		Autoplay
	} from "swiper";
	import myHead from '../../components/myHead/myhead';
	import myFoot from '../../components/myFoot/myfoot';
	import productList from '../../components/productList/productlist';
	import shopList from '../../components/shopList/shopList';
	import common from '../../assets/js/utils/common';
	import headDefault from '../../assets/images/head.png';
	import Pagination from '../../components/pagination/pagination';

	export default {
		name: "index",
		data() {
			return {
				headDefault,
				input1: '',
				input2: '',
				is_show_cate: false,
				is_sub_cate_hover: false,
				is_login: false,
				is_register: false,
				login_type: 1,
				reg_step: 1,
				list: [],
				is_code_login: false,
				reg_phone: "",
				reg_code: "",
				reg_pwd: "",
				reg_pwd_confirm: "",
				remember_pwd: false,
				is_get_phone_code: false,
				is_reg_submit: false,
				reg_checked: false,
				phone_second: 60,
				imageCode: "",
				login_phone: "",
				login_pwd: "",
				login_code: "",
				is_login_submit: false,
				checkBindTimer: null,
				userId: "",
				loadingCode: false,
				userinfo: "",
				avatar: "",
				is_loading: false,
				product_loading: false,
				cateGroupList: [],
				currGroupName: '',
				subCateList: [],
				market: null,
				searchParams: {
					cate_id: [0, 0],
					site_id: 0,
					style_id: 0,
					market_id: 0,
					sort_type: 0,
					name_type: 0,
          shop_id: '',
				},
				page_data: {
					limit: 20,
					page: 1,
					total: 0,
				},
				advertData: "",
				bannerList: [],
				bannerSwiper: null,
				newsList: [],
				orderCounts: {
					no_pay: 0,
					carry: 0,
					delivery: 0,
					after_sales: 0
				},
				yqm: "",
				yq_grade: "",
				checkList: [],
				marketList: [],
				styleList: [],
				site_index: 0,
				siteList: [],
				searchText: '',
				showFilter: true,
				filterText: '',
				checkExtList: [],
				ageingList: [],
				publicList: [],
				showPublic: false,
				showAgeing: false,
				ageing_text: '',
				public_text: '',
				timeRange: '',
				showPublicRange: false,
        shopLists: [],
				
			}
		},
		components: {
			myHead,
			myFoot,
			productList,
			Pagination,
			shopList
		},
		mounted() {
			// this.$nextTick(function () {
			// 	window.addEventListener('scroll', this.onScroll)
			// })
			// this.checkLoginCookie()
		},
		computed: {
			...mapState({
				users: (state) => state.login.userinfo,
				avatars: (state) => state.login.avatar,
				ucmenu: (state) => state.login.ucmenu,
			}),
		},
		created() {
			this.userinfo = this.users ? this.users : ""
			this.avatar = this.avatars || headDefault
			var query = this.$route.query
			if (query.reg) {
				this.is_register = true
				this.yqm = query.yqm
				this.yq_grade = query.yq_grade
			}
			if(query.name_type){
				this.searchParams.name_type = query.name_type
			}
			if(query.name){
				this.searchParams.name = query.name
				this.searchText = query.name
			}
			if(query.site_id){
				this.searchParams.site_id = parseInt(query.site_id)
			}
			if(query.cate_1){
				this.searchParams.cate_id[0] = parseInt(query.cate_1)
			}
			if(query.cate_2){
				this.searchParams.cate_id[1] = parseInt(query.cate_2)
			}
			if(query.market_id){
				this.searchParams.market_id = parseInt(query.market_id)
			}
			if(query.style_id){
				this.searchParams.style_id = parseInt(query.style_id)
			}
			
			if(query.page){
				this.page_data.page = parseInt(query.page)
			}
			
			this.init()
		},
		destroyed() {
			// window.removeEventListener('scroll', this.onScroll)
		},
		methods: {
			...mapActions({
				doAddAllProduct: "cart/doAddAllProduct",
				doSubmitPH: "cart/doSubmitPH",
				getMallCateData: "index/getMallCateData",
				getMallProductList: "index/getMallProductList",
				getBusinessOrderCount: "index/getBusinessOrderCount",
				getCodeData: "login/getCodeData",
				getQrcodeData: "login/getQrcodeData",
				checkWxBind: "login/checkWxBind",
				DoAccountLogin: "login/DoAccountLogin",
				DoCodeLogin: "login/DoCodeLogin",
				DoRegister: "login/DoRegister",
				getIndexBannerData: "index/getIndexBannerData",
				getAnnouncementData: "index/getAnnouncementData",
				getUcenterMenu: "login/getUcenterMenu",
			}),
			...mapMutations({
				OUT_LOGIN: "login/OUT_LOGIN"
			}),

			allPuhuo() {
				let product_ids = this.$refs.products.select_product_ids
				if (product_ids.length == 0) {
					this.$message({
						message: '请先勾选铺货商品',
						type: 'warning',
					});
					return
				}
				this.$refs.child.showShopList()

				// this.doAddAllProduct({data:{product_ids:product_ids},success:(res) => {
				// 	if(res.code == 200){
				// 		this.$message({message: res.msg,type: 'success',});
				// 	}else{
				// 		this.$message({message: res.msg,type: 'error',});
				// 	}
				// }})

			},
			phSubmit(val, strategy) { //立即铺货
				let param = {
					shop_id: val,
					type: '1',
					product_id: this.$refs.products.select_product_ids,
					strategy: strategy
				}
				this.doSubmitPH({
					data: param,
					success: (res) => {
						if (res.code === 200) {
							this.$message({
								message: res.msg,
								type: 'success',
							});
							setTimeout(() => {
								this.$refs.child.closeShopList()
							}, 300)
						} else {
							this.$message({
								message: res.msg,
								type: 'error',
							});
						}
					}
				})
			},
			codeLoginClick() {
				this.login_type = 1
				setTimeout(() => {
					this.loadingCode = true
				}, 100)
				this.getLoginCode('', true, '1')
			},
			accountLoginClick() {
				this.login_type = 2
				clearTimeout(this.checkBindTimer)
			},
			init() {
				// this.getOrderCount()
				this.getCateList()
				
				// this.getAdcertData()
				// this.getNewsData()
			},
			gotoImageSearch() {
				let param = this.searchParams
				this.$router.push({
					name: 'search_image',
					query: {}
				})
			},
			gotoIndexSearch() {
				let param = this.searchParams
				this.$router.push({
					name: 'search_index',
					query: param
				})
			},
			getUCmenu() {
				this.getUcenterMenu({
					data: {},
					success: (res) => {
						if (res.status == 200) {
							console.log('getUCmenu', res)
							// this.employeeData.data = res.data
						} else {
							this.$message({
								message: res.msg,
								type: 'error',
							});
						}
					}
				})
			},
			loginSubmit() { //登录提交
				if (!this.login_phone) {
					this.$message({
						message: '请输入手机号码',
						type: 'error',
					});
					return false
				}
				if (!this.is_code_login) { //账号密码登录
					if (!this.login_pwd) {
						this.$message({
							message: '请输入密码',
							type: 'error',
						});
						return false
					}
					if (!this.is_login_submit) {
						this.is_login_submit = true
						this.DoAccountLogin({
							data: {
								mobile: this.login_phone,
								password: this.login_pwd,
							},
							success: (res) => {
								if (res.code === 200) {
									this.setLoginCookie()
									this.$message({
										message: res.msg,
										type: 'success',
									});
									if (res.data == 'null') {
										window.location.reload()
										return
									}
									this.userinfo = res.data.userinfo
									this.avatar = res.data.userinfo.avatar || headDefault
									this.getUCmenu()
									this.refresh()
									if (res.data.userinfo.is_follow == 0) {
										this.login_type = 3
										this.getLoginCode(this.login_phone, true)
									} else {
										this.closeMask()
										console.log('sssss')
									}
								} else {
									this.$message({
										message: res.msg,
										type: 'error',
									});
									this.is_login_submit = false
								}
							}
						})
					}
				} else { //短信验证码登录
					if (!this.login_code) {
						this.$message({
							message: '请获取短信验证码',
							type: 'error',
						});
						return false
					}
					if (!this.is_login_submit) {
						this.is_login_submit = true
						this.DoCodeLogin({
							data: {
								mobile: this.login_phone,
								code: this.login_code,
							},
							success: (res) => {
								if (res.code === 200) {
									console.log('code login success', res)
									this.userinfo = res.data.userinfo
									this.avatar = res.data.userinfo.avatar || headDefault
									this.getUCmenu()
									this.refresh()
									this.$message({
										message: res.msg,
										type: 'success',
									});
									if (res.data.userinfo.is_follow == 0) {
										this.login_type = 3
										this.getLoginCode(this.login_phone, true)
									} else {
										this.closeMask()
									}
								} else {
									this.$message({
										message: res.msg,
										type: 'error',
									});
									this.is_login_submit = false
								}
							}
						})
					}
				}
			},
			regSubmit() { //注册提交
				if (!common._verify_mobile(this.reg_phone)) {
					this.$message({
						message: '请输入正确手机号',
						type: 'error',
					});
					return false
				}
				if (!this.reg_code) {
					this.$message({
						message: '请获取短信验证码',
						type: 'error',
					});
					return false
				}
				if (!this.reg_pwd) {
					this.$message({
						message: '请输入密码',
						type: 'error',
					});
					return false
				}
				if (!this.reg_pwd_confirm) {
					this.$message({
						message: '请再次输入密码',
						type: 'error',
					});
					return false
				}
				if (this.reg_pwd != this.reg_pwd_confirm) {
					this.$message({
						message: '请确保两次输入的密码一致',
						type: 'error',
					});
					return false
				}
				if (!this.reg_checked) {
					this.$message({
						message: '请阅读并同意用户协议',
						type: 'error',
					});
					return false
				}
				if (!this.is_reg_submit) {
					this.is_reg_submit = true
					this.DoRegister({
						data: {
							mobile: this.reg_phone,
							code: this.reg_code,
							password: this.reg_pwd,
							true_password: this.reg_pwd_confirm,
							yqm: this.yqm,
							yq_grade: this.yq_grade,
						},
						success: (res) => {
							console.log('register success', res)
							if (res.code === 200) {
								this.$message({
									message: '注册成功~',
									type: 'success',
								});
								this.reg_step = 2
								this.loadingCode = true
								this.getLoginCode(this.reg_phone, true)
								setTimeout(() => {
									this.is_reg_submit = false
									this.reg_phone = ''
									this.reg_code = ''
									this.reg_pwd = ''
									this.reg_pwd_confirm = ''
								}, 1500)
							} else {
								this.$message({
									message: res.msg,
									type: 'error',
								});
								this.is_reg_submit = false
							}
						}
					})
				}
			},
			getPhoneCode(ev) { //获取短信验证码
				let phone = this.is_login ? this.login_phone : this.reg_phone
				if (!common._verify_mobile(phone)) {
					this.$message({
						message: '请输入正确手机号',
						type: 'error',
					});
					return false
				}
				if (!this.is_get_phone_code) {
					this.is_get_phone_code = true
					this.getCodeData({
						data: {
							mobile: phone,
							event: ev,
						},
						success: (res) => {
							if (res.code === 200) {
								console.log('get phone code', res)
								this.$message({
									message: '发送成功~',
									type: 'success',
								});
								let timer = setInterval(() => {
									this.phone_second -= 1
									if (this.phone_second == 0) {
										clearInterval(timer)
										this.phone_second = 60
										this.is_get_phone_code = false
									}
								}, 1000)
							} else {
								this.$message({
									message: res.msg,
									type: 'error',
								});
								this.is_get_phone_code = false
							}
						}
					})
				}

			},
			closeMask() { //关闭登录注册弹窗
				this.is_login = false
				this.is_register = false
				this.is_get_phone_code = false
				this.phone_second = 60
				this.reg_step = 1
				clearTimeout(this.checkBindTimer)
			},
			openLoginMask() { //打开登录弹窗
				this.is_login = true
				setTimeout(() => {
					this.loadingCode = true
				}, 100)
				this.getLoginCode('', true, '1')
			},
			getLoginCode(phone = '', isCheck = false, type = '2') { //获取登录二维码,type=1表示直接通过二维码扫码登录，不需要传手机号
				this.getQrcodeData({
					data: {
						mobile: phone,
						level: '2',
						bind_uid: '0',
						type: type,
					},
					success: (res) => {
						if (res.code === 200) {
							console.log('get qr code', res)
							this.imageCode = res.msg.ticket_img
							setTimeout(() => {
								this.loadingCode = false
							}, 1000)
							if (isCheck) {
								this.int_uid = res.msg.int_uid
								this.checkBind(res.msg.int_uid)
							}
						} else {
							this.$message({
								message: res.msg,
								type: 'error',
							});
						}
					}
				})
			},
			checkBind(id) { //检测是否微信绑定成功
				this.checkWxBind({
					data: {
						int_uid: id,
					},
					success: (res) => {
						console.log('check login', res)
						if (res.code === 200) {
							this.userinfo = res.data.userinfo
							this.avatar = res.data.userinfo.avatar || headDefault
							this.getUCmenu()
							this.$message({
								message: res.msg,
								type: 'success',
							});
							this.closeMask()
						} else {
							this.checkBindTimer = setTimeout(() => {
								this.checkBind(this.int_uid)
							}, 1500)
						}
					}
				})
			},
			bindCheckExtChange(){
				let arr = this.checkExtList
				this.searchParams.is_returnable = arr.includes(1) ? 1 : 0
				this.searchParams.is_changeable = arr.includes(2) ? 1 : 0
				this.searchParams.drop_same = arr.includes(3) ? 1 : 0
				this.searchParams.drop_puhuo = arr.includes(4) ? 1 : 0
        this.searchParams.join_collect = arr.includes(5) ? 1 : 0;
		this.searchParams.is_deliver = arr.includes(6) ? 1 : 0;
		this.searchParams.is_preferred = arr.includes(7) ? 1 : 0;

				this.refresh()
			},

			bindAgeingTap(item){
				let ageing_type = this.searchParams.ageing_type
				this.searchParams.ageing_type = ageing_type == item.type ? 0 : item.type
				this.showAgeing = false
				this.ageing_text = item.text
				this.refresh()
			},
			bindCleanAgeing(){
				this.searchParams.ageing_type = 0
				this.showAgeing = false
				this.ageing_text = ''
				this.refresh()
			},
			bindPublicTap(item){
				if(item.type == 999){
					this.showPublicRange = true
					return
				}
				this.showPublicRange = false
				let public_type = this.searchParams.public_type
				this.searchParams.public_type = public_type == item.type ? 0 : item.type
				this.showPublic = false
				this.public_text = item.text
				this.refresh()
			},
			bindPublicRangeChange(e){
				this.public_text = e[0] + '至' + e[1]
				this.showPublic = false
				this.searchParams.time_range = e
				this.searchParams.public_type = 999
				this.refresh()
			},
			bindCleanPublic(){
				this.searchParams.public_type = 0
				this.showPublic = false
				this.public_text = ''
				this.refresh()
			},
			outLogin() { //退出登录
				this.$message({
					message: '已退出登录',
					type: 'success',
				});
				this.OUT_LOGIN()
				this.userinfo = ''
				this.is_login_submit = false
				this.login_type = 1
				this.phone_second = 60
				if (!this.remember_pwd) {
					this.login_phone = ''
					this.login_pwd = ''
				}
				this.login_code = ''
				this.refresh()
			},
			getAdcertData() { //获取banner及广告
				this.getIndexBannerData({
					data: {},
					success: (res) => {
						if (res.code == 200) {
							this.advertData = res.data.advert
							this.bannerList = res.data.banners
							this.$nextTick(() => {
								this.bannerSwiper = new Swiper('.swiper_wrap .swiper', {
									autoplay: {
										delay: 3000,
										stopOnLastSlide: false,
										disableOnInteraction: false,
									},
									modules: [Navigation, Autoplay],
									navigation: {
										nextEl: ".swiper_wrap .swiper_btn.next",
										prevEl: ".swiper_wrap .swiper_btn.prev",
									},
									loop: true,
									observer: true,
									observeParents: true,

								})
							})
						}
					}
				})
			},
			getNewsData() { //获取公告列表
				this.getAnnouncementData({
					data: {
						limit: 3,
						page: 1
					},
					success: (res) => {
						if (res.code == 200) {
							// console.log('获取公告列表',res)
							this.newsList = res.data.data
						}
					}
				})
			},
			//获取商家订单数量
			getOrderCount() {
				this.getBusinessOrderCount({
					data: {},
					success: (res) => {
						if (res.code == 200) {
							this.orderCounts = res.data.orderCounts
						} else {
							this.orderCounts = {
								no_pay: 0,
								carry: 0,
								delivery: 0,
								after_sales: 0
							}
						}
						this.is_loading = false
					}
				})
			},
			//获取商品分类
			getCateList() {
				this.is_loading = true
				this.getMallCateData({
					data: {},
					success: (res) => {
						if (res.code == 200 && res.data.lists.length > 0) {
							this.cateGroupList = res.data.lists
							if(!this.searchParams.cate_id[0]){
								this.subCateList = this.cateGroupList[0].cate_list[0].children
							}else{
								let cateGroup = this.cateGroupList.find(val => val.cate_ids == this.searchParams.cate_id[0])
								this.subCateList = cateGroup.cate_list[0].children
							}
							this.marketList = res.data.market_list
							this.styleList = res.data.style_list
							this.siteList = res.data.site_list
							this.ageingList = res.data.ageing_list
							this.publicList = res.data.public_list
						}
						this.is_loading = false
						this.getProduct()
					}
				})
			},
			selectAll() {
				this.$refs.products.checkAll()
			},
			//获取商品列表
			getProduct() {
				if (this.product_loading) return
				this.product_loading = true
				this.list = []
				this.setFilterText()
				let searchParams = this.searchParams
				let param = {
					limit: this.page_data.limit,
					page: this.page_data.page,
					state: this.state
				}
				Object.assign(param, searchParams)
				
				// console.log(param)
				this.getMallProductList({
					data: param,
					success: (res) => {
						if (res.code === 200) {

							this.list = res.data.data
							this.page_data.total = res.data.total
              if(param.page == 1){
                this.shopLists = res.data.filter_data.shop_list
              }
						} else {
							this.$message({
								message: res.msg,
								type: 'error',
							});
						}
						this.product_loading = false
						
						document.documentElement.scrollTop = 470
						// this.page_data.page ++
					}
				})
			},
			//滚动加载
			onScroll() {
				// 滚动条到最顶端的距离
				let scrolled = document.documentElement.scrollTop || document.body.scrollTop;
				let clientHeight = document.documentElement.clientHeight
				let scrollHeight = document.documentElement.scrollHeight
				if (scrolled + clientHeight + 400 >= scrollHeight) {
					this.loadMore()
				}
			},
			//刷新列表
			refresh() {
				this.page_data.page = 1
				this.searchText = this.searchParams.name
				this.getProduct()
				// this.getOrderCount()
			},
			//清空筛选
			reset() {
				this.searchParams.cate_id = [0,0]
				this.searchParams.site_id = 0
				this.searchParams.market_id = 0
				this.searchParams.style_id = 0
				this.subCateList = []
        this.searchParams.shop_id = ''
				this.refresh()
			},
			bindFilterShow(){
				this.showFilter = !this.showFilter
			},
			//加载商品
			loadMore() {
				if (this.page_data.total <= this.list.length) {
					return false
				} else {
					this.getProduct()
				}
			},

			searchTap(name, val) {
				if (!this.searchParams[name] || this.searchParams[name] != val) {
					this.searchParams[name] = val
				} else {
					this.searchParams[name] = 0
				}
				this.refresh()
			},
			bindSortTypeTap(val){
				let sort_type = this.searchParams.sort_type
				this.searchParams.sort_type = sort_type==val?0:val
				this.refresh()
			},
			siteCommand(val) {
				let siteList = this.siteList
				this.site_index = val
				this.$set(this.searchParams, 'site_id', siteList[val].site_id)
				this.refresh()
			},
			typeCommand(val){
				this.$set(this.searchParams,'name_type',val)
				// this.searchParams.name_type = val
				if (val == 1) {//搜档口
					this.searchParams.name_type = 0
					const fullPath = this.$router.resolve('search_shop').href;
					window.open(fullPath, '_blank'); 
					return
				}
				if(val == 3){
					// let param = this.searchParams
					// this.$router.push({ name: 'search_image', query: {}})
					this.searchParams.name_type = 0
					const fullPath = this.$router.resolve('search_image').href;
					window.open(fullPath, '_blank'); 
					return
				}
				// this.refresh()
			},
			priceCommand(val) {
				this.searchParams.price_sort = val
				this.refresh()
			},
			timeCommand(val) {
				this.searchParams.time_sort = val
				this.refresh()
			},
			bindSiteTap(val){
				this.searchParams.site_id = val
				this.refresh()
			},
			bindMarketTap(val) {
				this.searchParams.market_id = val
				this.refresh()
			},
			bindStyleTap(val) {
				if (!this.userinfo) {
					this.$message({
						message: '请先登录',
						type: 'warning',
					});
					this.openLoginMask()
					return
				}
				this.searchParams.style_id = val
				this.refresh()
			},
			supplierCommand(val) {
				console.log(val)
				this.searchParams.market_id = val
				this.refresh()
			},
			bindEventStop() {
				return false;
			},
			bindCateGroupTab(row) {
				if(row){
					this.subCateList = row.cate_list[0].children
					this.searchParams.cate_id[0] = parseInt(row.cate_ids)
				}else{
					this.subCateList = []
					this.searchParams.cate_id[0] = 0
				}
				this.searchParams.cate_id[1] = 0
				this.refresh()
			},
		
			bindCateTap(second_cid) {
				this.searchParams.cate_id[1] = second_cid
				this.refresh()
			},
			
			setFilterText(){
				let filterText = ''
				let params = this.searchParams
				const {...query} = this.$route.query
				let site_id = params.site_id
				if(site_id){
					var site = this.siteList.find(val => val.site_id == site_id)
					filterText = (filterText?(filterText+' > '+site.site_name):site.site_name)
				}
				query.site_id = params.site_id
				
				let market_id = params.market_id
				if(market_id){
					var market = this.marketList.find(val => val.market_id == market_id)
					filterText = (filterText?(filterText+' > '+market.market_name):market.market_name)
				}
				query.market_id = params.market_id
				
				let cate_1 = params.cate_id[0]?params.cate_id[0]:0
				let cate_2 = params.cate_id[1]?params.cate_id[1]:0
				if(cate_1){
					var cateGroup = this.cateGroupList.find(val => val.cate_ids == cate_1)
					filterText = (filterText?(filterText+' > '+cateGroup.name):cateGroup.name)
					if(cate_2){
						var cate = this.subCateList.find(val => val.id == cate_2)
						filterText = (filterText?(filterText+' > '+cate.name):cate.name)
					}
				}
				query.cate_1 = cate_1 
				query.cate_2 = cate_2 
				
				let style_id = params.style_id
				if(style_id){
					var style = this.styleList.find(val => val.style_id == style_id)
					filterText = (filterText?(filterText+' > '+style.style_name):style.style_name)
				}
				query.style_id = style_id
				
				query.page = this.page_data.page
				
				query.name = this.searchParams.name
				this.filterText = filterText
				this.$router.replace({query: query})
			},
			//检查是否记住密码
			checkLoginCookie() {
				var businessLoginData = this.$cookies.get('businessLoginData')
				if (businessLoginData) {
					this.remember_pwd = businessLoginData.remember_pwd
					this.login_phone = businessLoginData.login_phone
					this.login_pwd = businessLoginData.login_pwd
				}
			},
			//手机登录记住密码
			setLoginCookie() {
				if (this.remember_pwd && this.login_type == 2) {
					var businessLoginData = {
						remember_pwd: true,
						login_phone: this.login_phone,
						login_pwd: this.login_pwd
					}
					this.$cookies.set('businessLoginData', businessLoginData, '30d')
				} else {
					var businessLoginData = false
					this.$cookies.set('businessLoginData', businessLoginData, '1s')
				}
			}
		}
	}
</script>

<style scoped>
	.full-center {
		width: 100%;
		background-color: #fff;
		padding: 30px 0 34px
	}

	.register_main {
		width: 100%;
		height: auto;
		box-sizing: border-box;
		background: #fff;
		padding: 0 22px 30px;
	}

	.register_main .icon-guanbi {
		font-size: 12px;
		color: #999;
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
		z-index: 222;
	}

	.register_main .title {
		line-height: 1;
		font-size: 20px;
		margin-bottom: 16px;
	}

	.register_main .in_box {
		width: 100%;
		height: 38px;
		box-sizing: border-box;
		border: 1px solid #ddd;
		border-radius: 4px;
		display: flex;
		margin-top: 14px;
		align-items: center;
	}

	.register_main .in_box .txt {
		margin-left: 10px;
		width: 70px;
		font-size: 14px;
		color: #999;
	}

	.register_main .in_box .in {
		flex: 1;
		width: 0;
		margin-right: 14px;
		font-size: 14px;
		color: #333333;
	}

	.register_main .in_box .get_code {
		font-size: 14px;
		color: #333333;
		padding: 0 12px;
		border-left: 1px solid #ccc;
		line-height: 1;
		cursor: pointer;
	}

	.register_main .register_btn {
		width: 100%;
		height: 38px;
		border-radius: 4px;
		background: #fb5758;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-size: 14px;
		cursor: pointer;
		margin-top: 35px;
	}

	.register_main .check_line {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin-top: 14px;
	}

	.register_main .code {
		display: block;
		width: 155px;
		height: 155px;
		margin: 16px auto 0;
	}

	.register_main .code_txt {
		margin-top: 28px;
		text-align: center;
		font-size: 14px;
		color: #666666;
	}

	.register_main .check_line .link {
		color: #fb5758;
		cursor: pointer;
	}

	.login_main {
		width: 100%;
		height: auto;
		box-sizing: border-box;
		background: #fff;
		padding: 36px 0 28px;
	}

	.login_main .icon-guanbi {
		font-size: 12px;
		color: #999;
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
		z-index: 222;
	}

	.login_main .login_type {
		padding: 14px 0;
		border-bottom: 1px solid #eee;
		display: flex;
		line-height: 1;
	}

	.login_main .login_type .tab {
		flex: 1;
		text-align: center;
		font-size: 18px;
		cursor: pointer;
	}

	.login_main .login_type .tab.active {
		font-weight: bold;
		color: #fb5758;
	}

	.login_main .code_login {
		margin: 38px 0 55px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.login_main .code_login .code {
		width: 155px;
		height: 155px;
	}

	.login_main .code_login .txt {
		margin-top: 24px;
		line-height: 1;
		font-size: 14px;
		color: #666666;
		text-align: center;
	}

	.login_main .account_login {
		margin: 0 22px 55px;
		padding-top: 20px;
	}

	.login_main .account_login .in_box {
		width: 100%;
		height: 38px;
		box-sizing: border-box;
		border: 1px solid #ddd;
		border-radius: 4px;
		display: flex;
		margin-top: 14px;
		align-items: center;
	}

	.login_main .account_login .in_box .icons {
		width: 38px;
		height: 100%;
		border-right: 1px solid #ddd;
		background: #f5f5f5;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login_main .account_login .in_box .icons .iconfont {
		font-size: 20px;
		color: #cccccc;
	}

	.login_main .account_login .in_box .in {
		flex: 1;
		width: 0;
		margin: 0 14px;
		font-size: 14px;
		color: #333333;
	}

	.login_main .account_login .in_box .txt {
		margin-left: 10px;
		width: 60px;
		font-size: 14px;
		color: #999;
	}

	.login_main .account_login .in_box .get_code {
		font-size: 14px;
		color: #666;
		padding: 0 12px;
		border-left: 1px solid #ccc;
		line-height: 1;
		cursor: pointer;
	}

	.login_main .account_login .change_way {
		margin-top: 20px;
		line-height: 1;
		display: flex;
		justify-content: space-between;
	}

	.login_main .account_login .change_way>span {
		font-size: 14px;
		color: #333333;
		cursor: pointer;
	}

	.login_main .account_login .login_btn {
		width: 100%;
		height: 38px;
		border-radius: 4px;
		background: #fb5758;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-size: 14px;
		cursor: pointer;
		margin-top: 35px;
	}

	.login_main .go_register {
		margin: 0 30px;
		text-align: right;
		font-size: 14px;
		line-height: 1;
	}

	.login_main .go_register>a {
		color: #fb5758;
		text-decoration: underline;
		cursor: pointer;
	}

	.home_main {
		width: 100%;
		height: auto;
		margin-top: 20px;
		margin-bottom: 40px;
	}

	.search_wrap {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		font-size: 16px;
	}

	.search_wrap .search_box {
		width: 900px;
		height: 50px;
		box-sizing: border-box;
		background-color: #ffffff;
		border-radius: 4px 0vw 0vw 4px;
		border: solid 2px #fa5757;
		display: flex;
		align-items: center;
	}

	.search_wrap .search_box .type {
		width: 84px;
		color: #333333;
		cursor: pointer;
		text-align: center;
		line-height: 26px;
		border-right: 1px solid #ddd;
	}

	.search_wrap .search_box .in {
		flex: 1;
		width: 0;
		margin: 0 15px;
		color: #333333;
		font-size: 16px;
	}

	.search_wrap .search_box .icon-xiangji {
		margin-right: 14px;
		font-size: 24px;
		color: #999;
		cursor: pointer;
	}

	.search_wrap .search_btn {
		width: 132px;
		height: 50px;
		background-image: linear-gradient(180deg, #fa5757 0%, #fb6d6d 100%), linear-gradient(#ffffff, #ffffff);
		background-blend-mode: normal, normal;
		border-radius: 0vw 4px 4px 0vw;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-weight: bold;
		cursor: pointer;
	}
	
	.filter-title {
	    width: 100%;
	    margin-top: 30px
	}
	
	.filter-title .search-input {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center
	}
	
	.filter-title .search-input .label {
	    font-size: 14px;
	    color: #666
	}
	
	.filter-title .search-input .val {
	    font-size: 14px;
	    color: #333;
	    margin-left: 10px
	}
	
	.filter-box {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    margin-top: 14px;
	    overflow: hidden;
	    width: 1200px;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    background-color: #fff
	}
	
	.filter-box .filter-header {
	    -webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	    padding: 0 21px;
	    min-height: 46px;
	    background-color: #f9f9f9;
	    border: 1px solid #eee;
	    font-size: 14px;
	    width: 100%;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box
	}
	
	.filter-box .filter-header .header-left,.filter-box .filter-header {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center
	}
	
	.filter-box .filter-header .header-left .label {
	    font-size: 14px;
	    color: #666
	}
	
	.filter-box .filter-header .header-left .val {
	    font-size: 14px;
	    color: #000;
	    margin-left: 20px
	}
	
	.filter-box .filter-header .header-left .reset {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    margin-left: 20px;
	    cursor: pointer
	}
	
	.filter-box .filter-header .header-left .reset span {
	    color: #fa5757
	}
	
	.filter-box .filter-header .header-left .reset i {
	    color: #fa5757;
	    margin-left: 4px
	}
	
	.filter-box .filter-header .header-right {
	    cursor: pointer
	}
	
	.filter-box .filter-header .header-right i {
	    margin-left: 8px
	}
	
	.filter-box .filter-list {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    padding: 0 21px;
	    min-height: 46px;
	    line-height: 46rpx;
	    font-size: 14px;
	    border-bottom: 1px solid #eee;
	    width: 100%;
	    border-left: 1px solid #eee;
	    border-right: 1px solid #eee;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box
	}
	
	.filter-box.show {
	    max-height: 1200px;
	    -webkit-transition: all .5s;
	    transition: all .5s;
	    overflow-y: scroll
	}
	
	.filter-box::-webkit-scrollbar {
	    width: 0
	}
	
	.filter-box.hide {
	    max-height: 46px;
	    -webkit-transition: all .5s;
	    transition: all .5s
	}
	
	.filter-box .filter-list .label {
	    width: 55px;
	    line-height: 46px;
	    color: #999
	}
	
	.filter-box .filter-list .list {
	    width: calc(100% - 55px)
	}
	
	.filter-box .filter-list .list .item {
	    line-height: 46px;
	    display: inline-block;
	    margin-right: 50px;
	    color: #333;
	    cursor: pointer
	}
	
	.filter-box .filter-list .list .item.active {
	    color: #fa5757
	}

	.top_section {
		margin-top: 30px;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.top_section .left_type {
		width: 270px;
		height: 490px;
		background-color: #ffffff;
		box-sizing: border-box;
		padding-bottom: 12px;
		padding-top: 24px;
		position: relative;
	}

	.left_type .type_show {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
	}

	.left_type .type_show .title {
		display: flex;
		align-items: center;
		line-height: 1;
		font-weight: bold;
		margin-bottom: 10px;
		padding: 0 20px;
	}

	.left_type .type_show .title .icon-fenlei2 {
		font-size: 16px;
		margin-right: 8px;
	}

	.left_type .type_show .title .site_name {
		font-weight: bold;
		cursor: pointer;
	}

	.left_type .type_show .title .el-icon-arrow-down {
		font-weight: bold;
	}

	.left_type .type_show .type_line {
		width: 100%;
		min-height: 32px;
		display: flex;
		align-items: flex-start;
		padding: 0 10px 0 20px;
		box-sizing: border-box;
		font-size: 14px;
		flex-direction: column;
	}

	.left_type .type_show::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	.left_type .type_show::-webkit-scrollbar-track {
		background: #efefef;
	}

	.left_type .type_show::-webkit-scrollbar-thumb {
		background: #bfbfbf;
	}

	.left_type .type_show::-webkit-scrollbar-corner {
		background: #333;
	}

	.left_type .type-head {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.left_type .type-head .icon-wrap {
		display: inline-flex;
		height: 32px;
		width: 20px;
		align-items: center;
		justify-content: center;
	}

	.left_type .type-head .icon-wrap .cate-icon {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}

	.left_type .type_show .type_line .iconfont {
		font-size: 18px;
		display: none;
	}

	.left_type .type_show .type_line .con {
		flex: 1;
		width: 0;
		margin-left: 10px;
		font-weight: bold;
		color: #333333;
	}

	.left_type .type_show .type_line .con>span:hover {
		color: #fa5757;
		text-decoration: underline;
		cursor: pointer;
	}

	.left_type .type_show .type_line .con>em {
		font-weight: bold;
		padding: 0 6px;
	}

	.left_type .type_show .type_line .con>em:last-child {
		display: none;
	}

	.left_type .type_show .type_line:hover {
		background: #ffedee;
	}

	.left_type .type_show .type_line.hover {
		background: #ffedee;
	}

	.left_type .type_show .type_line.hover .con>span {
		color: #fa5757;
		cursor: pointer;
	}

	.left_type .type_show .type_line .sub-cate-wrap {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: unset;
		margin-bottom: 10px;
	}

	.left_type .type_show .type_line .sub-cate-wrap .sub-cate {
		line-height: 32px;
		margin-right: 10px;
		display: inline-block;
		cursor: pointer;
		color: #666666;
	}

	.left_type .type_show .type_line .sub-cate-wrap .sub-cate.active {
		color: #fa5757;
		text-decoration: underline;
	}

	.left_type .type_show .type_line .el-icon-arrow-right {
		font-weight: bold;
	}

	.left_type .type_show .type_line:hover .el-icon-arrow-right {
		color: #fa5757;
	}

	.left_type .type_show .type_line .sub-cate-wrap .sub-cate:hover {
		color: #fa5757;
	}

	.left_type .detail_hide {
		width: 930px;
		height: auto;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 270px;
		z-index: 22;
		background: #fff;
		box-shadow: 0 0 10px 0 #eeeeee;
		visibility: hidden;
		opacity: 0;
		transition: all .3s ease-in-out;
	}

	.left_type .detail_hide.active {
		visibility: visible;
		opacity: 1;
	}

	.detail_hide .detail_label {
		height: auto;
		min-height: 300px;
		box-sizing: border-box;
		padding: 30px 30px 40px;
	}

	.detail_hide .detail_label:first-child {
		margin-top: 0;
	}

	.detail_hide .detail_label .lv1 {
		color: #e4393c;
		font-weight: bold;
		margin-bottom: 6px;
		font-size: 16px !important;
		border-bottom: 1px solid #eeeeee;
		padding: 15px 0;
	}

	.detail_hide .detail_label .label_line {
		margin-top: 12px;
		font-size: 12px;
		line-height: 24px;
		display: flex;
	}

	.detail_hide .detail_label .label_line .tab_lv2 {
		font-weight: bold;
		width: 90px;
		padding-right: 20px;
		display: none;
	}

	.detail_hide .detail_label .label_line .tab_lv2 .lv2 {
		cursor: pointer;
	}

	.detail_hide .detail_label .label_line .tabs_wrap {
		flex: 1;
		width: 0;
		display: flex;
		flex-wrap: wrap;
	}

	.detail_hide .detail_label .label_line .tabs_wrap .tab_lv3 {
		margin-right: 15px;
		cursor: pointer;
		line-height: 3;
		width: 130px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #666666;
	}

	.detail_hide .detail_label .label_line .tabs_wrap .tab_lv3:hover {
		color: #fa5757;
	}

	.detail_hide .detail_label .label_line .tabs_wrap .tab_lv3.active {
		color: #fa5757;
		text-decoration: underline;
	}

	.label_line .tab_lv2 .lv2:hover,
	.label_line .tabs_wrap .tab_lv3:hover {
		color: #e4393c;
	}

	.top_section .center_main {
		width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.center_main .swiper_wrap {
		width: 100%;
		height: 256px;
	}

	.center_main .swiper_wrap .swiper {
		height: 100%;
	}

	.center_main .swiper_wrap .swiper_btn {
		width: 26px;
		height: 50px;
		background: rgba(0, 0, 0, .4);
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 22;
	}

	.center_main .swiper_wrap .swiper_btn.prev {
		left: 0;
	}

	.center_main .swiper_wrap .swiper_btn.next {
		right: 0;
	}

	.center_main .swiper_wrap .swiper_btn .icon-fanhui {
		font-size: 20px;
		color: #ffffff;
	}

	.center_main .swiper_wrap .swiper_btn:hover .icon-fanhui {
		color: #fa5757;
	}

	.center_main .swiper_wrap .swiper_btn.next .icon-fanhui {
		transform: rotate(180deg);
	}

	.center_main .adx_box {
		width: 100%;
		height: 223px;
		display: block;
	}

	.top_section .right_con {
		width: 308px;
		display: flex;
		flex-direction: column;
		background: #fff;
		position: relative;
	}

	.right_con .supplier_login_wrap {
		width: 100%;
		text-align: center;
		margin-top: 10px;
		color: #000;
	}

	.right_con .supplier_login_wrap .supplier_login_btn {
		font-size: 14px;
		color: #000 !important;
	}

	.right_con .supplier_login_wrap .supplier_login_btn:hover {
		color: var(--red) !important;
	}

	.right_con .user_wrap {
		padding: 28px 16px 26px;
		background: #faedee;
	}

	.right_con .user_wrap .user_con {
		display: flex;
		align-items: center;
	}

	.right_con .user_wrap .user_con .head_box {
		width: 54px;
		height: 54px;
		background: #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.right_con .user_wrap .user_con .head_box .iconfont {
		font-size: 34px;
		color: #fdaeae;
	}

	.right_con .user_wrap .user_con .head_box .head {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	.right_con .user_wrap .user_con .con {
		flex: 1;
		width: 0;
		margin-left: 15px;
		font-size: 14px;
		line-height: 1;
	}

	.right_con .user_wrap .user_con .con .txt {
		margin-top: 8px;
		color: #999;
	}

	.right_con .user_wrap .btn_wrap {
		margin-top: 18px;
		display: flex;
		justify-content: space-between;
	}

	.right_con .user_wrap .btn_wrap .btn {
		width: 130px;
		height: 32px;
		box-sizing: border-box;
		border-radius: 5em;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		cursor: pointer;
	}

	.right_con .user_wrap .btn_wrap .btn:nth-child(1) {
		background: #fa5757;
		color: #ffffff;
	}

	.right_con .user_wrap .btn_wrap .btn:nth-child(2) {
		background: #fff;
		color: #fa5757;
		border: 1px solid #fa5757;
	}

	.right_con .order_nums {
		width: 100%;
		height: 88px;
		border-bottom: 1px solid #eeeeee;
		display: flex;
		align-items: center;
	}

	.right_con .order_nums .num_box {
		flex: 1;
		text-align: center;
		line-height: 1;
		cursor: pointer;
	}

	.right_con .order_nums .num_box .num {
		font-size: 20px;
	}

	.right_con .order_nums .num_box .txt {
		font-size: 14px;
		color: #666666;
		margin-top: 10px;
	}

	.right_con .news_wrap {
		flex: 1;
		height: 0;
		padding: 18px 16px 0;
	}

	.right_con .news_wrap .news_img {
		width: 91px;
		height: 18px;
		margin-bottom: 4px;
	}

	.right_con .news_wrap .news_link {
		margin-top: 12px;
		font-size: 14px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		color: #333333;
		line-height: 1;
		cursor: pointer;
	}

	.right_con .news_wrap .news_link:hover {
		color: #fa5757;
		text-decoration: underline;
	}

	.product_section {
	    width: 100%;
	    height: auto;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    background: #fff;
	    margin-top: 20px;
	    padding-top: 20px
	}
	
	.product_section .screen_wrap {
	    position: relative;
	    width: calc(100% - 40px);
	    height: 46px;
	    border-bottom: 1px solid #eee;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    margin: 0 20px;
	    background-color: #f9f9f9;
	    border: 1px solid #eee;
	    padding: 0 20px;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box
	}
	
	.screen_wrap .title {
	    font-weight: 700;
	    margin-right: 60px
	}
	
	.screen_wrap .tab {
	    font-size: 14px;
	    margin-right: 40px;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    cursor: pointer
	}
	
	.screen_wrap .tab:hover {
	    color: var(--red);
	    /* text-decoration: underline */
	}
	
	
	.screen_wrap .tab.active {
	    color: #fa5757
	}
	
	.screen_wrap .in_box {
	    width: 54px;
	    height: 24px;
	    border: 1px solid #ccc;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    padding: 0 6px;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    font-size: 14px;
	    color: #999
	}
	
	.screen_wrap .in_box .in {
	    margin-left: 6px
	}
	
	.screen_wrap .in_line {
	    width: 20px;
	    color: #999
	}
	
	.screen_wrap .in_btn,.screen_wrap .in_line {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center
	}
	
	.screen_wrap .in_btn {
	    margin-left: 12px;
	    width: auto;
	    padding: 0 10px;
	    height: 26px;
	    background: #ddd;
	    cursor: pointer;
	    font-size: 12px;
	    color: #666
	}
	
	.ext-screen {
	    position: relative;
	    width: calc(100% - 40px);
	    height: 46px;
	    height: 42px;
	    background-color: #fff;
	    border-left: 1px solid #eee;
	    border-right: 1px solid #eee;
	    border-bottom: 1px solid #eee;
	    margin: 0 20px;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    padding: 0 20px;
	    -webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between
	}
	.ageing-list{
		position: absolute;
		width: 1160px;
		left: 0;
		top: 0;
		height: 42px;
		background-color: #fff;
		box-shadow: 0 4px 4px rgba(0, 0,0,0.1 );
		z-index: 10;
	}
	.ageing-list,.ext-screen .check-list,.ext-screen {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	}
	
	.ageing-list .ageing-item {
	    font-size: 12px;
	    color: #333;
	    margin-left: 25px;
	    cursor: pointer
	}
	
	.ageing-list .ageing-item.active {
	    color: #fa5757
	}
	
	
	/deep/.ext-screen .check-list .el-checkbox__inner {
	    width: 16px;
	    height: 16px
	}
	
	/deep/.ext-screen .check-list .el-checkbox__label {
	    font-size: 12px!important
	}
	
	/deep/.el_input {
	    margin: 14px auto 0!important;
	    width: 100%
	}
	
	.screen_wrap .totle-text {
	    position: absolute;
	    right: 20px;
	    font-size: 14px;
	    color: #666
	}
</style>
<style>
	.ageing-box{
		width: 100vw !important;
		position: absolute !important;
		left: 50% !important;
		background: unset !important;
		transform: translateX(-580px);
		box-shadow: unset !important;
		border: unset !important;
	}
	.register_mask .el-dialog__body,
	.login_mask .el-dialog__body,
	.login_mask .el-dialog__header {
		padding: 0;
	}

	.el_input.el_in2 .el-input__prefix {
		top: 0;
		left: 0;
	}

	.el_input {
		display: flex;
		margin-top: 14px;
	}

	.el_input .el-input__inner {
		height: 38px;
		line-height: 38px;
		padding-left: 58px;
		flex: 1;
		width: 0;
	}

	.el_input .el-input__inner:focus {
		border-color: var(--red);
	}

	.el_input.el_in2 .el-input__inner {
		padding-left: 90px;
	}

	.account_login .el_input .el-input__prefix {
		left: 1px;
		top: 1px;
		height: 36px;
	}

	.el_input .el-input__prefix .icons {
		width: 38px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 1px;
		left: 2px;
		background: #f5f5f5;
		border-right: 1px solid #ddd;
		border-radius: 4px 0 0 4px;
	}

	.el_input .el-input__prefix .icons .iconfont {
		font-size: 20px;
		color: #cccccc;
	}

	.el_input .el-input__prefix .txts {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding-left: 10px;
	}

	.el_input .el-input__suffix {
		display: flex;
	}

	.el_input .el-input__suffix .el-input__suffix-inner {
		display: flex;
		align-items: center;
	}

	.el_input .el-input__suffix .el-input__suffix-inner .getcode {
		font-size: 14px;
		color: #333333;
		padding: 0 12px;
		border-left: 1px solid #ccc;
		line-height: 1;
		cursor: pointer;
	}

	.register_main .check_line .el-checkbox {
		margin-right: 8px;
		margin-top: 2px;
	}

	.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #DCDFE6 !important;
	}

	.el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #fb5758 !important;
		border-color: #fb5758 !important;
	}

	.el-dropdown-menu__item:focus,
	.el-dropdown-menu__item:not(.is-disabled):hover {
		background-color: #ffedee;
		color: #fa5757;
	}
</style>